import React from "react";
import RenderRoutes from "components/renderRoutes";

const AddressAllocationComponent = React.lazy(
  () => import("routes/pendingActions/addressAllocations")
);
const OrderApprovalComponent = React.lazy(
  () => import("routes/pendingActions/orderApprovals")
);
const StockApprovalComponent = React.lazy(
  () => import("routes/pendingActions/stockApprovals")
);

export const ORDER_APPROVAL_ROUTE = "/actions/order-approvals/";
export const STOCK_APPROVAL_ROUTE = "/actions/stock-approvals/";
export const ADDRESS_ALLOCATION_ROUTE = "/actions/address-allocations/";

export const routes = {
  name: "actions/",
  path: "/actions/",
  component: RenderRoutes,
  access_roles: ["super_admin", "master_admin", "branch_admin"],
  routes: [
    {
      allowAll: true,
      exact: true,
      name: "address-allocations",
      path: ADDRESS_ALLOCATION_ROUTE,
      component: AddressAllocationComponent,
    },
    {
      allowAll: true,
      exact: true,
      name: "order-approvals",
      path: ORDER_APPROVAL_ROUTE,
      component: OrderApprovalComponent,
    },
    {
      allowAll: true,
      exact: true,
      name: "stock-approvals",
      path: STOCK_APPROVAL_ROUTE,
      component: StockApprovalComponent,
    },
  ],
};

export const menuRoutes = [
  {
    allowAll: true,
    branchSelection: true,
    key: "order-approvals",
    label: "Order Approvals",
    route: ORDER_APPROVAL_ROUTE,
  },
  {
    allowAll: true,
    branchSelection: true,
    key: "address-allocations",
    label: "Address Allocation",
    route: ADDRESS_ALLOCATION_ROUTE,
  },
  {
    allowAll: true,
    branchSelection: true,
    key: "stock-approvals",
    label: "Unverified stock",
    route: STOCK_APPROVAL_ROUTE,
  },
];

import React from "react";
import RenderRoutes from "components/renderRoutes";

const CatalogueCategoriesListComponent = React.lazy(
  () => import("routes/catalogue/categories/list")
);
const CatalogueCategoriesAddComponent = React.lazy(
  () => import("routes/catalogue/categories/save")
);

const CatalogueSubCategoriesListComponent = React.lazy(
  () => import("routes/catalogue/subCategories/list")
);
const CatalogueSubCategoriesSaveComponent = React.lazy(
  () => import("routes/catalogue/subCategories/save")
);

const CatalogueBrandListComponent = React.lazy(
  () => import("routes/catalogue/brands/list")
);
const CatalogueBrandSaveComponent = React.lazy(
  () => import("routes/catalogue/brands/save")
);

const CatalogueUnitListComponent = React.lazy(
  () => import("routes/catalogue/units/list")
);
const CatalogueUnitSaveComponent = React.lazy(
  () => import("routes/catalogue/units/save")
);

const CatalogueAttributeListComponent = React.lazy(
  () => import("routes/catalogue/attributes/list")
);
const CatalogueAttributeSaveComponent = React.lazy(
  () => import("routes/catalogue/attributes/save")
);

const CatalogueProductListComponent = React.lazy(
  () => import("routes/catalogue/products/list")
);
const CatalogueProductSaveComponent = React.lazy(
  () => import("routes/catalogue/products/save")
);

export const CATALOGUE_CATEGORY_ROUTE = "/catalogue/categories/";
export const CATALOGUE_CATEGORY_DETAIL_ROUTE = `${CATALOGUE_CATEGORY_ROUTE}:categoryId/`;
export const CATALOGUE_CATEGORY_ADD_ROUTE = `${CATALOGUE_CATEGORY_ROUTE}add/`;
export const CATALOGUE_CATEGORY_EDIT_ROUTE = `${CATALOGUE_CATEGORY_DETAIL_ROUTE}edit/`;

export const CATALOGUE_SUB_CATEGORY_ROUTE = "/catalogue/sub-categories/";
export const CATALOGUE_SUB_CATEGORY_DETAIL_ROUTE = `${CATALOGUE_SUB_CATEGORY_ROUTE}:subCategoryId/`;
export const CATALOGUE_SUB_CATEGORY_ADD_ROUTE = `${CATALOGUE_SUB_CATEGORY_ROUTE}add/`;
export const CATALOGUE_SUB_CATEGORY_EDIT_ROUTE = `${CATALOGUE_SUB_CATEGORY_DETAIL_ROUTE}edit/`;

export const CATALOGUE_BRAND_ROUTE = "/catalogue/brands/";
export const CATALOGUE_BRAND_DETAIL_ROUTE = `${CATALOGUE_BRAND_ROUTE}:brandId/`;
export const CATALOGUE_BRAND_ADD_ROUTE = `${CATALOGUE_BRAND_ROUTE}add/`;
export const CATALOGUE_BRAND_EDIT_ROUTE = `${CATALOGUE_BRAND_DETAIL_ROUTE}edit/`;

export const CATALOGUE_UNIT_ROUTE = "/catalogue/units/";
export const CATALOGUE_UNIT_DETAIL_ROUTE = `${CATALOGUE_UNIT_ROUTE}:unitId/`;
export const CATALOGUE_UNIT_ADD_ROUTE = `${CATALOGUE_UNIT_ROUTE}add/`;
export const CATALOGUE_UNIT_EDIT_ROUTE = `${CATALOGUE_UNIT_DETAIL_ROUTE}edit/`;

export const CATALOGUE_ATTRIBUTE_ROUTE = "/catalogue/attributes/";
export const CATALOGUE_ATTRIBUTE_DETAIL_ROUTE = `${CATALOGUE_ATTRIBUTE_ROUTE}:attributeId/`;
export const CATALOGUE_ATTRIBUTE_ADD_ROUTE = `${CATALOGUE_ATTRIBUTE_ROUTE}add/`;
export const CATALOGUE_ATTRIBUTE_EDIT_ROUTE = `${CATALOGUE_ATTRIBUTE_DETAIL_ROUTE}edit/`;

export const CATALOGUE_PRODUCT_ROUTE = "/catalogue/products/";
export const CATALOGUE_PRODUCT_DETAIL_ROUTE = `${CATALOGUE_PRODUCT_ROUTE}:productId/`;
export const CATALOGUE_PRODUCT_ADD_ROUTE = `${CATALOGUE_PRODUCT_ROUTE}add/`;
export const CATALOGUE_PRODUCT_EDIT_ROUTE = `${CATALOGUE_PRODUCT_DETAIL_ROUTE}edit/`;

export const getCatalogueCategoryEditRoute = (categoryId: string): string => {
  return CATALOGUE_CATEGORY_EDIT_ROUTE.replace(":categoryId", categoryId);
};

export const getCatalogueSubCategoryEditRoute = (
  subCategoryId: string
): string => {
  return CATALOGUE_SUB_CATEGORY_EDIT_ROUTE.replace(
    ":subCategoryId",
    subCategoryId
  );
};

export const getCatalogueBrandEditRoute = (brandId: string): string => {
  return CATALOGUE_BRAND_EDIT_ROUTE.replace(":brandId", brandId);
};

export const getCatalogueUnitEditRoute = (unitId: string): string => {
  return CATALOGUE_UNIT_EDIT_ROUTE.replace(":unitId", unitId);
};

export const getCatalogueAttributeEditRoute = (attributeId: string): string => {
  return CATALOGUE_ATTRIBUTE_EDIT_ROUTE.replace(":attributeId", attributeId);
};

export const getCatalogueProductEditRoute = (productId: string): string => {
  return CATALOGUE_PRODUCT_EDIT_ROUTE.replace(":productId", productId);
};

export const routes = {
  name: "catalogue",
  path: "/catalogue/",
  component: RenderRoutes,
  allowAll: true,
  routes: [
    {
      exact: true,
      name: "catalogue-categories-list",
      path: CATALOGUE_CATEGORY_ROUTE,
      component: CatalogueCategoriesListComponent,
      allowAll: true,
    },
    {
      exact: true,
      name: "catalogue-categories-add",
      path: CATALOGUE_CATEGORY_ADD_ROUTE,
      component: CatalogueCategoriesAddComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-categories-edit",
      path: CATALOGUE_CATEGORY_EDIT_ROUTE,
      component: CatalogueCategoriesAddComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-sub-categories-list",
      path: CATALOGUE_SUB_CATEGORY_ROUTE,
      component: CatalogueSubCategoriesListComponent,
      allowAll: true,
    },
    {
      exact: true,
      name: "catalogue-sub-categories-add",
      path: CATALOGUE_SUB_CATEGORY_ADD_ROUTE,
      component: CatalogueSubCategoriesSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-sub-categories-edit",
      path: CATALOGUE_SUB_CATEGORY_EDIT_ROUTE,
      component: CatalogueSubCategoriesSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-brands-list",
      path: CATALOGUE_BRAND_ROUTE,
      component: CatalogueBrandListComponent,
      allowAll: true,
    },
    {
      exact: true,
      name: "catalogue-brands-edit",
      path: CATALOGUE_BRAND_EDIT_ROUTE,
      component: CatalogueBrandSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-brands-add",
      path: CATALOGUE_BRAND_ADD_ROUTE,
      component: CatalogueBrandSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-units-list",
      path: CATALOGUE_UNIT_ROUTE,
      component: CatalogueUnitListComponent,
      allowAll: true,
    },
    {
      exact: true,
      name: "catalogue-units-add",
      path: CATALOGUE_UNIT_ADD_ROUTE,
      component: CatalogueUnitSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-units-edit",
      path: CATALOGUE_UNIT_EDIT_ROUTE,
      component: CatalogueUnitSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-attributes-list",
      path: CATALOGUE_ATTRIBUTE_ROUTE,
      component: CatalogueAttributeListComponent,
      allowAll: true,
    },
    {
      exact: true,
      name: "catalogue-attributes-add",
      path: CATALOGUE_ATTRIBUTE_ADD_ROUTE,
      component: CatalogueAttributeSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-attributes-edit",
      path: CATALOGUE_ATTRIBUTE_EDIT_ROUTE,
      component: CatalogueAttributeSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-products-list",
      path: CATALOGUE_PRODUCT_ROUTE,
      component: CatalogueProductListComponent,
      allowAll: true,
    },
    {
      exact: true,
      name: "catalogue-products-add",
      path: CATALOGUE_PRODUCT_ADD_ROUTE,
      component: CatalogueProductSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "catalogue-products-edit",
      path: CATALOGUE_PRODUCT_EDIT_ROUTE,
      component: CatalogueProductSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
  ],
};

export const menuRoutes = [
  {
    allowAll: true,
    key: "categories",
    label: "Categories",
    branchSelection: true,
    route: CATALOGUE_CATEGORY_ROUTE,
  },
  {
    allowAll: true,
    key: "sub-categories",
    label: "Sub Categories",
    branchSelection: true,
    route: CATALOGUE_SUB_CATEGORY_ROUTE,
  },
  {
    allowAll: true,
    key: "brands",
    label: "Brands",
    branchSelection: false,
    route: CATALOGUE_BRAND_ROUTE,
  },
  {
    allowAll: true,
    key: "units",
    label: "Units",
    branchSelection: false,
    route: CATALOGUE_UNIT_ROUTE,
  },
  {
    allowAll: true,
    key: "attributes",
    label: "Attributes",
    branchSelection: false,
    route: CATALOGUE_ATTRIBUTE_ROUTE,
  },
  {
    allowAll: true,
    key: "products",
    label: "Products",
    branchSelection: true,
    route: CATALOGUE_PRODUCT_ROUTE,
  },
];

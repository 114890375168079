import { AppRouteType } from "interfaces";
import React, { FC } from "react";
import { Route } from "react-router-dom";

// TODO: change type of component
type RouteWithSubRoutesProps = {
  path?: string;
  component: any;
  routes?: Array<AppRouteType>;
};

const RouteWithSubRoutes: FC<RouteWithSubRoutesProps> = (props) => {
  return (
    <Route
      // exact
      path={props.path}
      render={(routeProps) => (
        // pass the sub-routes down to keep nesting
        <props.component {...routeProps} routes={props.routes} />
      )}
    />
  );
};

export default RouteWithSubRoutes;

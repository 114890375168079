/*eslint-disable*/
import React, { useReducer, useContext, createContext } from "react";

const BranchContext = createContext({});

// TODO: change type of reducer
type BranchProviderProps = {
  reducer: any;
  children: React.ReactNode;
};

export const BranchProvider = ({ reducer, children }: BranchProviderProps) => {
  const contextValue = useReducer(reducer, {});
  return (
    <BranchContext.Provider value={contextValue}>
      {children}
    </BranchContext.Provider>
  );
};

// TODO: change return type
export const BranchConsumer = (): any => {
  const contextValue = useContext(BranchContext);
  return contextValue;
};

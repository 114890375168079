export const ADMIN_USER_API_PATH = "user/a/";
export const ADMIN_LOGIN_API_PATH = `${ADMIN_USER_API_PATH}login/`;

export const DAYS_API_PATH = "days/";
export const NOTIFICATION_API_PATH = "notifications/";

export const CATALOG_CATEGORY_API_PATH = "catalog/categories/";
export const CATALOG_BRANCH_CATEGORY_TOGGLE_API_PATH = `${CATALOG_CATEGORY_API_PATH}{}/toggle_enable/`;

export const CATALOG_PRODUCT_API_PATH = "catalog/products/";
export const CATALOG_PRODUCT_IMAGE_UPLOAD_API_PATH = `${CATALOG_PRODUCT_API_PATH}upload_image/`;

export const CATALOG_PRODUCT_VARIANT_API_PATH = "catalog/products-variants/";
export const CATALOG_BRANCH_PRODUCT_VARIANT_TOGGLE_API_PATH = `${CATALOG_PRODUCT_VARIANT_API_PATH}{}/toggle_enable/`;
export const CATALOG_BRANCH_PRODUCT_VARIANT_FEATURED_API_PATH = `${CATALOG_PRODUCT_VARIANT_API_PATH}{}/toggle_featured/`;

export const CATALOG_UNIT_API_PATH = "catalog/units/";
export const CATALOG_BRAND_API_PATH = "catalog/brands/";
export const CATALOG_ATTRIBUTE_API_PATH = "catalog/attributes/";
export const CATALOG_SUB_CATEGORY_API_PATH = "catalog/subcategories/";

export const DRIVER_PROFILE_API_PATH = "driver/profiles/";

export const CUSTOMER_PROFILE_API_PATH = "customer/profiles/";
export const CUSTOMER_FEEDBACK_API_PATH = "customer/feedbacks/";
export const CUSTOMER_PROFILE_UNALLOCATED_ADDRESS_API_PATH = `${CUSTOMER_PROFILE_API_PATH}un-allocated-addresses/`;

export const DELIVERY_BRANCH_API_PATH = "delivery/branches/";
export const DELIVERY_AREA_API_PATH = "delivery/areas/";
export const DELIVERY_ROUTE_API_PATH = "delivery/routes/";
export const DELIVERY_TIME_SLOT_API_PATH = "delivery/time-slots/";
export const DELIVERY_ROUTE_OPTIMISE_API_PATH =
  "delivery/routes/{}/optimise_routes/";

export const ORDER_API_PATH = "orders/";
export const ORDER_SUBSCRIPTION_API_PATH = "orders/subscriptions/";

export const WALLET_TRANSACTION_API_PATH = "wallet-transactions/";

export const ADMIN_USER_LIST_API_PATH = "admin-users/";

export const WAREHOUSE_API_PATH = "warehouses/";
export const WAREHOUSE_STOCK_APPROVAL_API_PATH = `${WAREHOUSE_API_PATH}approvals/`;
export const WAREHOUSE_INVENTORY_API_PATH = `${WAREHOUSE_API_PATH}{}/inventory/`;

export const WAREHOUSE_STOCK_API_PATH = `${WAREHOUSE_API_PATH}{}/stock-transactions/`;
export const WAREHOUSE_STOCK_DETAIL_API_PATH = `${WAREHOUSE_STOCK_API_PATH}{stockId}/`;

export const WAREHOUSE_STOCK_APPROVE_API_PATH = `${WAREHOUSE_STOCK_DETAIL_API_PATH}approve/`;
export const WAREHOUSE_STOCK_REJECT_API_PATH = `${WAREHOUSE_STOCK_DETAIL_API_PATH}reject/`;

import React from "react";

import NotFoundPage from "components/404Page";
import RenderRoutes from "components/renderRoutes";

import { routes as catalogRoutes } from "routes/catalogue";
import { routes as orderRoutes } from "routes/orders";
import { routes as customerRoutes } from "routes/customers";
import { routes as deliveryRoutes } from "routes/delivery";
import { routes as warehouseRoutes } from "routes/warehouse";
import { routes as userRoutes } from "routes/users";
import { routes as pendingActionRoutes } from "routes/pendingActions";

const AuthLoginComponent = React.lazy(() => import("routes/login"));
const LayoutComponent = React.lazy(() => import("components/appLayout"));
const DashboardComponent = React.lazy(() => import("routes/dashboard"));
const NotificationComponent = React.lazy(() => import("routes/notification"));

export const INDEX_ROUTE = "/";
export const NOTIFICATION_ROUTE = "/notification/";

export const NON_LOGIN_ROUTES = [
  {
    name: "index",
    path: INDEX_ROUTE,
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: "login",
        path: INDEX_ROUTE,
        component: AuthLoginComponent,
      },
    ],
  },
];

export const LOGGED_IN_ROUTES = [
  {
    name: "index",
    path: INDEX_ROUTE,
    component: LayoutComponent,
    allowAll: true,
    routes: [
      {
        exact: true,
        allowAll: true,
        name: "dashboard",
        path: INDEX_ROUTE,
        component: DashboardComponent,
      },
      catalogRoutes,
      orderRoutes,
      customerRoutes,
      deliveryRoutes,
      warehouseRoutes,
      userRoutes,
      pendingActionRoutes,
      {
        exact: true,
        allowAll: true,
        name: "notifications",
        path: NOTIFICATION_ROUTE,
        component: NotificationComponent,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const AUTHORIZATION_KEY = "agni_token";
export const DEFAULT_FORM_VALIDATION_MESSAGES = {
  email: "Enter a valid email",
  required: "${label} is required!",
};

export const GLOBAL_BRANCH = {
  uuid: "global",
  name: "Global",
  location: {
    type: "",
    coordinates: [],
  },
};

import React from "react";
import RenderRoutes from "components/renderRoutes";

const CustomerProfileListComponent = React.lazy(
  () => import("routes/customers/profiles/list")
);
const CustomerProfileEditComponent = React.lazy(
  () => import("routes/customers/profiles/edit")
);
const CustomerProfileCreateComponent = React.lazy(
  () => import("routes/customers/profiles/create")
);

export const CUSTOMER_PROFILE_ROUTE = "/customer/profiles/";
export const CUSTOMER_PROFILE_CREATE_ROUTE = "/customer/profiles/add/";
export const CUSTOMER_PROFILE_DETAIL_ROUTE = "/customer/profiles/:profileId/";
export const CUSTOMER_PROFILE_EDIT_ROUTE = `${CUSTOMER_PROFILE_DETAIL_ROUTE}edit/`;

export const getCustomerProfileEditRoute = (profileId: string): string => {
  return CUSTOMER_PROFILE_EDIT_ROUTE.replace(":profileId", profileId);
};

export const routes = {
  name: "customer",
  path: "/customer/",
  component: RenderRoutes,
  access_roles: ["super_admin", "master_admin", "branch_admin"],
  routes: [
    {
      exact: true,
      allowAll: true,
      name: "customer-profile-list",
      path: CUSTOMER_PROFILE_ROUTE,
      component: CustomerProfileListComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "customer-profile-create",
      path: CUSTOMER_PROFILE_CREATE_ROUTE,
      component: CustomerProfileCreateComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "customer-profile-edit",
      path: CUSTOMER_PROFILE_EDIT_ROUTE,
      component: CustomerProfileEditComponent,
    },
  ],
};

export const menuRoutes = [
  {
    allowAll: true,
    key: "customers",
    label: "Customers",
    branchSelection: true,
    route: CUSTOMER_PROFILE_ROUTE,
  },
];

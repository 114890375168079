import React from "react";
import RenderRoutes from "components/renderRoutes";

const UserListComponent = React.lazy(() => import("routes/users/list"));
const UserSaveComponent = React.lazy(() => import("routes/users/save"));

export const ADMIN_USER_ROUTE = "/users/";
export const ADMIN_USER_ADD_ROUTE = "/users/add/";
export const ADMIN_USER_EDIT_ROUTE = "/users/:userId/";

export const getAdminUserEditRoute = (userId: string): string => {
  return ADMIN_USER_EDIT_ROUTE.replace(":userId", userId);
};

export const routes = {
  name: "users/",
  path: "/users/",
  component: RenderRoutes,
  access_roles: ["super_admin"],
  routes: [
    {
      allowAll: true,
      exact: true,
      name: "admin-users-list",
      path: ADMIN_USER_ROUTE,
      component: UserListComponent,
    },
    {
      allowAll: true,
      exact: true,
      name: "admin-users-add",
      path: ADMIN_USER_ADD_ROUTE,
      component: UserSaveComponent,
    },
    {
      allowAll: true,
      exact: true,
      name: "admin-users-edit",
      path: ADMIN_USER_EDIT_ROUTE,
      component: UserSaveComponent,
    },
  ],
};

import React from "react";
import RenderRoutes from "components/renderRoutes";

const DeliveryBranchListComponent = React.lazy(
  () => import("routes/delivery/branches/list")
);
const DeliveryBranchSaveComponent = React.lazy(
  () => import("routes/delivery/branches/save")
);
const DeliveryAreaListComponent = React.lazy(
  () => import("routes/delivery/areas/list")
);
const DeliveryAreaSaveComponent = React.lazy(
  () => import("routes/delivery/areas/save")
);
const DeliveryTimeSlotListComponent = React.lazy(
  () => import("routes/delivery/timeSlots/list")
);
const DeliveryTimeSlotSaveComponent = React.lazy(
  () => import("routes/delivery/timeSlots/save")
);
const DeliveryDriverListComponent = React.lazy(
  () => import("routes/delivery/drivers/list")
);
const DeliveryDriverSaveComponent = React.lazy(
  () => import("routes/delivery/drivers/save")
);
const DeliveryRouteListComponent = React.lazy(
  () => import("routes/delivery/routes/list")
);
const DeliveryRouteSaveComponent = React.lazy(
  () => import("routes/delivery/routes/save")
);
const DeliveryRouteMasterOptmizeComponent = React.lazy(
  () => import("routes/delivery/routes/masterOptimize")
);

export const DELIVERY_BRANCH_ROUTE = "/delivery/branches/";
export const DELIVERY_BRANCH_DETAIL_ROUTE = `${DELIVERY_BRANCH_ROUTE}:branchId/`;
export const DELIVERY_BRANCH_ADD_ROUTE = `${DELIVERY_BRANCH_ROUTE}add/`;
export const DELIVERY_BRANCH_EDIT_ROUTE = `${DELIVERY_BRANCH_DETAIL_ROUTE}edit/`;

export const DELIVERY_AREA_ROUTE = "/delivery/areas/";
export const DELIVERY_AREA_DETAIL_ROUTE = `${DELIVERY_AREA_ROUTE}:areaId/`;
export const DELIVERY_AREA_ADD_ROUTE = `${DELIVERY_AREA_ROUTE}add/`;
export const DELIVERY_AREA_EDIT_ROUTE = `${DELIVERY_AREA_DETAIL_ROUTE}edit/`;

export const DELIVERY_TIME_SLOT_ROUTE = "/delivery/time-slots/";
export const DELIVERY_TIME_SLOT_DETAIL_ROUTE = `${DELIVERY_TIME_SLOT_ROUTE}:slotId/`;
export const DELIVERY_TIME_SLOT_ADD_ROUTE = `${DELIVERY_TIME_SLOT_ROUTE}add/`;
export const DELIVERY_TIME_SLOT_EDIT_ROUTE = `${DELIVERY_TIME_SLOT_DETAIL_ROUTE}edit/`;

export const DELIVERY_DRIVER_ROUTE = "/delivery/drivers/";
export const DELIVERY_DRIVER_DETAIL_ROUTE = `${DELIVERY_DRIVER_ROUTE}:driverId/`;
export const DELIVERY_DRIVER_ADD_ROUTE = `${DELIVERY_DRIVER_ROUTE}add/`;
export const DELIVERY_DRIVER_EDIT_ROUTE = `${DELIVERY_DRIVER_DETAIL_ROUTE}edit/`;

export const DELIVERY_ROUTE_ROUTE = "/delivery/routes/";
export const DELIVERY_ROUTE_DETAIL_ROUTE = `${DELIVERY_ROUTE_ROUTE}:routeId/`;
export const DELIVERY_ROUTE_ADD_ROUTE = `${DELIVERY_ROUTE_ROUTE}add/`;
export const DELIVERY_ROUTE_EDIT_ROUTE = `${DELIVERY_ROUTE_DETAIL_ROUTE}edit/`;
export const DELIVERY_ROUTE_MASTER_OPTIMIZE = `${DELIVERY_ROUTE_DETAIL_ROUTE}master-optimize/`;

export const getDeliveryBranchEditRoute = (branchId: string): string => {
  return DELIVERY_BRANCH_EDIT_ROUTE.replace(":branchId", branchId);
};

export const getDeliveryAreaEditRoute = (areaId: string): string => {
  return DELIVERY_AREA_EDIT_ROUTE.replace(":areaId", areaId);
};

export const getDeliveryTimeSlotEditRoute = (slotId: string): string => {
  return DELIVERY_TIME_SLOT_EDIT_ROUTE.replace(":slotId", slotId);
};

export const getDeliveryRouteEditRoute = (routeId: string): string => {
  return DELIVERY_ROUTE_EDIT_ROUTE.replace(":routeId", routeId);
};

export const getDeliveryRouteMasterOptimiseRoute = (
  routeId: string
): string => {
  return DELIVERY_ROUTE_MASTER_OPTIMIZE.replace(":routeId", routeId);
};

export const getDeliveryDriverEditRoute = (driverId: string): string => {
  return DELIVERY_DRIVER_EDIT_ROUTE.replace(":driverId", driverId);
};

export const routes = {
  name: "delivery",
  path: "/delivery/",
  component: RenderRoutes,
  access_roles: ["super_admin", "master_admin", "branch_admin"],
  routes: [
    {
      exact: true,
      name: "delivery-branches-list",
      path: DELIVERY_BRANCH_ROUTE,
      component: DeliveryBranchListComponent,
      access_roles: ["super_admin"],
    },
    {
      exact: true,
      access_roles: ["super_admin"],
      name: "delivery-branches-add",
      path: DELIVERY_BRANCH_ADD_ROUTE,
      component: DeliveryBranchSaveComponent,
    },
    {
      exact: true,
      access_roles: ["super_admin"],
      name: "delivery-branches-edit",
      path: DELIVERY_BRANCH_EDIT_ROUTE,
      component: DeliveryBranchSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-areas-list",
      path: DELIVERY_AREA_ROUTE,
      component: DeliveryAreaListComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-areas-add",
      path: DELIVERY_AREA_ADD_ROUTE,
      component: DeliveryAreaSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-areas-edit",
      path: DELIVERY_AREA_EDIT_ROUTE,
      component: DeliveryAreaSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-time-slots-list",
      path: DELIVERY_TIME_SLOT_ROUTE,
      component: DeliveryTimeSlotListComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-time-slots-add",
      path: DELIVERY_TIME_SLOT_ADD_ROUTE,
      component: DeliveryTimeSlotSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-time-slots-edit",
      path: DELIVERY_TIME_SLOT_EDIT_ROUTE,
      component: DeliveryTimeSlotSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-drivers-list",
      path: DELIVERY_DRIVER_ROUTE,
      component: DeliveryDriverListComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-drivers-add",
      path: DELIVERY_DRIVER_ADD_ROUTE,
      component: DeliveryDriverSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-drivers-edit",
      path: DELIVERY_DRIVER_EDIT_ROUTE,
      component: DeliveryDriverSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-routes-list",
      path: DELIVERY_ROUTE_ROUTE,
      component: DeliveryRouteListComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-routes-add",
      path: DELIVERY_ROUTE_ADD_ROUTE,
      component: DeliveryRouteSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-routes-edit",
      path: DELIVERY_ROUTE_EDIT_ROUTE,
      component: DeliveryRouteSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "delivery-routes-master-optimize",
      path: DELIVERY_ROUTE_MASTER_OPTIMIZE,
      component: DeliveryRouteMasterOptmizeComponent,
    },
  ],
};

export const menuRoutes = [
  {
    allowAll: true,
    branchSelection: false,
    key: "delivery-branches",
    label: "Delivery Branches",
    route: DELIVERY_BRANCH_ROUTE,
    access_roles: ["super_admin"],
  },
  {
    allowAll: true,
    branchSelection: true,
    key: "delivery-areas",
    label: "Delivery Areas",
    route: DELIVERY_AREA_ROUTE,
  },
  {
    allowAll: true,
    branchSelection: false,
    key: "delivery-time-slot",
    label: "Delivery Time Slot",
    route: DELIVERY_TIME_SLOT_ROUTE,
  },
  {
    allowAll: true,
    branchSelection: true,
    key: "delivery-drivers",
    label: "Delivery Drivers",
    route: DELIVERY_DRIVER_ROUTE,
  },
  {
    allowAll: true,
    branchSelection: true,
    key: "delivery-routes",
    label: "Delivery Routes",
    route: DELIVERY_ROUTE_ROUTE,
  },
];

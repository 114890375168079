import axiosClient from "axiosClient";
import * as endpoints from "./endpoints";

import { AUTHORIZATION_KEY } from "global_constants";

const setAccessToken = (token: string) => {
  axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const deleteAccessToken = () => {
  delete axiosClient.defaults.headers.common["Authorization"];
};

export const getProductImageFileUploadPath = () => {
  return `${axiosClient.defaults.baseURL}${endpoints.CATALOG_PRODUCT_IMAGE_UPLOAD_API_PATH}`;
};

interface LoginData {
  email: string;
  password: string;
}

const getBranchHeader = (branchId?: string) => {
  return branchId && branchId !== "global"
    ? {
        branch: branchId,
      }
    : {};
};

export const getGoogleLocationDetail = async (placeId: string) => {
  return (await axiosClient.get(`google-place-detail/?place_id=${placeId}`))
    .data;
};

export const getAdminUserDetail = async () => {
  const token = localStorage.getItem(AUTHORIZATION_KEY);
  if (token) {
    axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return (await axiosClient.get(endpoints.ADMIN_USER_API_PATH)).data;
  }
  throw new Error("No token provided");
};

export const loginAdmin = async (data: LoginData) => {
  deleteAccessToken();
  const { data: response } = await axiosClient.post(
    endpoints.ADMIN_LOGIN_API_PATH,
    data
  );
  setAccessToken(response.access);
  return response;
};

export class APIService {
  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  _getDetailUrl(objectId: string) {
    return `${this.apiUrl}${objectId}/`;
  }

  // _getHeaders(branchId?: string) {
  //   return branchId && branchId !== "global"
  //     ? {
  //         branch: branchId,
  //       }
  //     : {};
  // }

  async get(pageParam: number, filters = {}, branchId = "") {
    const params = {
      ...filters,
      ...(pageParam ? { page: pageParam.toString() } : {}),
    };
    const queryString = new URLSearchParams(params).toString();
    return (
      await axiosClient.get(this.apiUrl.concat("?", queryString), {
        headers: getBranchHeader(branchId),
      })
    ).data;
  }

  async list(filters = {}, branchId = "") {
    const queryString = new URLSearchParams(filters).toString();
    return (
      await axiosClient.get(this.apiUrl.concat("?", queryString), {
        headers: getBranchHeader(branchId),
      })
    ).data;
  }

  async retrieve(objectId: string, branchId = "") {
    return (
      await axiosClient.get(this._getDetailUrl(objectId), {
        headers: getBranchHeader(branchId),
      })
    ).data;
  }

  async create(data: Object, branchId = "") {
    return await axiosClient.post(this.apiUrl, data, {
      headers: {
        ...getBranchHeader(branchId),
        ...(data instanceof FormData
          ? {
              "Content-Type": "multipart/form-data",
            }
          : {}),
      },
    });
  }

  async update(objectId: string, data: Object, branchId = "") {
    return await axiosClient.patch(this._getDetailUrl(objectId), data, {
      headers: {
        ...getBranchHeader(branchId),
        ...(data instanceof FormData
          ? {
              "Content-Type": "multipart/form-data",
            }
          : {}),
      },
    });
  }

  async delete(objectId = "", branchId = "") {
    return await axiosClient.delete(this._getDetailUrl(objectId));
  }
}

export const toggleBranchCategory = async (
  categoryId: string,
  branchId: string
) => {
  return (
    await axiosClient.post(
      endpoints.CATALOG_BRANCH_CATEGORY_TOGGLE_API_PATH.replace(
        "{}",
        categoryId
      ),
      {},
      {
        headers: {
          branch: branchId,
        },
      }
    )
  ).data;
};

export const toggleBranchProductVariant = async (
  productVariantId: string,
  branchId: string
) => {
  return (
    await axiosClient.post(
      endpoints.CATALOG_BRANCH_PRODUCT_VARIANT_TOGGLE_API_PATH.replace(
        "{}",
        productVariantId
      ),
      {},
      {
        headers: {
          branch: branchId,
        },
      }
    )
  ).data;
};

export const featuredBranchProductVariant = async (
  productVariantId: string,
  branchId: string
) => {
  return (
    await axiosClient.post(
      endpoints.CATALOG_BRANCH_PRODUCT_VARIANT_FEATURED_API_PATH.replace(
        "{}",
        productVariantId
      ),
      {},
      {
        headers: {
          branch: branchId,
        },
      }
    )
  ).data;
};

export const optimiseDeliveryRoutes = async (
  routeId: string,
  branchId: string,
  data: any
) => {
  return await axiosClient.post(
    endpoints.DELIVERY_ROUTE_OPTIMISE_API_PATH.replace("{}", routeId),
    data,
    {
      headers: {
        branch: branchId,
      },
    }
  );
};

export const getStockApprovals = async (branchId?: string) => {
  return await axiosClient.get(endpoints.WAREHOUSE_STOCK_APPROVAL_API_PATH, {
    headers: getBranchHeader(branchId),
  });
};

export const approveStockApproval = async (
  warehouseId: string,
  stockId: string,
  branchId?: string
) => {
  let url = endpoints.WAREHOUSE_STOCK_APPROVE_API_PATH.replace(
    "{}",
    warehouseId
  );
  url = url.replace("{stockId}", stockId);
  return await axiosClient.post(
    url,
    {},
    {
      headers: getBranchHeader(branchId),
    }
  );
};

export const rejectStockApproval = async (
  warehouseId: string,
  stockId: string,
  branchId?: string
) => {
  let url = endpoints.WAREHOUSE_STOCK_REJECT_API_PATH.replace(
    "{}",
    warehouseId
  );
  url = url.replace("{stockId}", stockId);
  return await axiosClient.post(
    url,
    {},
    {
      headers: getBranchHeader(branchId),
    }
  );
};

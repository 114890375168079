import React, { useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import { isEmpty } from "_dash";
import { AppRouteType } from "interfaces";
import { LOGGED_IN_ROUTES, NON_LOGIN_ROUTES } from "routes";

import { useUser } from "hooks/useUser";

import RouteWithSubRoutes from "components/routeWithSubRoutes";

const App = () => {
  const [appRoutes, setAppRoutes] = useState<Array<AppRouteType>>([]);

  const { data: user, isLoading, isError } = useUser();

  function filterRoleBasedRoutes(arr: any) {
    return arr.filter((o: any) => {
      const keep =
        o["allowAll"] ||
        (o["access_roles"] && o["access_roles"].includes(user.admin_role));
      if (keep && o["routes"]) {
        o["routes"] = filterRoleBasedRoutes(o["routes"]);
      }
      return keep;
    });
  }

  useEffect(() => {
    if (!isLoading) {
      if (!isEmpty(user) && !isError) {
        setAppRoutes(filterRoleBasedRoutes(LOGGED_IN_ROUTES));
      } else {
        setAppRoutes(NON_LOGIN_ROUTES);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isError, user]);

  return (
    <div>
      <BrowserRouter>
        <Switch>
          {appRoutes.map((route: AppRouteType, i: number) => {
            return <RouteWithSubRoutes key={i} {...route} />;
          })}
        </Switch>
      </BrowserRouter>
    </div>
  );
};
export default App;

import React, { FC } from "react";
import { Spin } from "antd";
import { Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import NotFoundPage from "components/404Page";
import RouteWithSubRoutes from "components/routeWithSubRoutes";

type RenderRoutesProps = {
  routes: Array<any>;
};

const RenderRoutes: FC<RenderRoutesProps> = ({ routes }) => {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      }
    >
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={route.name} {...route} />;
        })}
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default RenderRoutes;

import React from "react";
import RenderRoutes from "components/renderRoutes";

const OrderOneTimeListComponent = React.lazy(
  () => import("routes/orders/oneTime/list")
);
const OrderOneTimeSaveComponent = React.lazy(
  () => import("routes/orders/oneTime/save")
);

const OrderSubscriptionListComponent = React.lazy(
  () => import("routes/orders/subscriptions/list")
);
const OrderSubscriptionSaveComponent = React.lazy(
  () => import("routes/orders/subscriptions/save")
);

export const ORDER_ONE_TIME_ROUTE = "/order/orders/";
export const ORDER_ONE_TIME_DETAIL_ROUTE = `${ORDER_ONE_TIME_ROUTE}:orderId/`;
export const ORDER_ONE_TIME_ADD_ROUTE = `${ORDER_ONE_TIME_ROUTE}add/`;
export const ORDER_ONE_TIME_EDIT_ROUTE = `${ORDER_ONE_TIME_DETAIL_ROUTE}edit/`;

export const ORDER_SUBSCRIPTION_ROUTE = "/order/subscriptions/";
export const ORDER_SUBSCRIPTION_DETAIL_ROUTE = `${ORDER_SUBSCRIPTION_ROUTE}:subscriptionId/`;
export const ORDER_SUBSCRIPTION_ADD_ROUTE = `${ORDER_SUBSCRIPTION_ROUTE}add/`;
export const ORDER_SUBSCRIPTION_EDIT_ROUTE = `${ORDER_SUBSCRIPTION_DETAIL_ROUTE}edit/`;

export const getOrderOneTimeEditRoute = (orderId: string): string => {
  return ORDER_ONE_TIME_EDIT_ROUTE.replace(":orderId", orderId);
};

export const getOrderSubscriptionEditRoute = (
  subscriptionId: string
): string => {
  return ORDER_SUBSCRIPTION_EDIT_ROUTE.replace(
    ":subscriptionId",
    subscriptionId
  );
};

export const routes = {
  name: "order",
  path: "/order/",
  component: RenderRoutes,
  access_roles: ["super_admin", "master_admin", "branch_admin"],
  routes: [
    {
      exact: true,
      allowAll: true,
      name: "order-one-time-list",
      path: ORDER_ONE_TIME_ROUTE,
      component: OrderOneTimeListComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "order-one-time-list",
      path: ORDER_ONE_TIME_ROUTE,
      component: OrderOneTimeListComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "order-one-time-add",
      path: ORDER_ONE_TIME_ADD_ROUTE,
      component: OrderOneTimeSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "order-one-time-edit",
      path: ORDER_ONE_TIME_EDIT_ROUTE,
      component: OrderOneTimeSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "order-subscription-list",
      path: ORDER_SUBSCRIPTION_ROUTE,
      component: OrderSubscriptionListComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "order-subscription-add",
      path: ORDER_SUBSCRIPTION_ADD_ROUTE,
      component: OrderSubscriptionSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "order-subscription-edit",
      path: ORDER_SUBSCRIPTION_EDIT_ROUTE,
      component: OrderSubscriptionSaveComponent,
    },
  ],
};

export const menuRoutes = [
  {
    allowAll: true,
    branchSelection: true,
    key: "one-time-orders",
    label: "One Time Orders",
    route: ORDER_ONE_TIME_ROUTE,
  },
  {
    allowAll: true,
    branchSelection: true,
    key: "subscriptions",
    label: "Subscriptions",
    route: ORDER_SUBSCRIPTION_ROUTE,
  },
];

import React from "react";
import RenderRoutes from "components/renderRoutes";

const WarehouseListComponent = React.lazy(
  () => import("routes/warehouse/list")
);
const WarehouseSaveComponent = React.lazy(
  () => import("routes/warehouse/save")
);
const WarehouseStockSaveComponent = React.lazy(
  () => import("routes/warehouse/stockSave")
);

const WarehouseInventoryComponent = React.lazy(
  () => import("routes/warehouse/inventory")
);

export const WAREHOUSE_MANAGEMENT_ROUTE = "/warehouse-management/";
export const WAREHOUSE_MANAGEMENT_ADD_ROUTE = `${WAREHOUSE_MANAGEMENT_ROUTE}add/`;
export const WAREHOUSE_MANAGEMENT_INVENTORY_ROUTE = `${WAREHOUSE_MANAGEMENT_ROUTE}inventory/`;
export const WAREHOUSE_MANAGEMENT_INSTOCK_ROUTE = `${WAREHOUSE_MANAGEMENT_ROUTE}instock/`;
export const WAREHOUSE_MANAGEMENT_OUTSTOCK_ROUTE = `${WAREHOUSE_MANAGEMENT_ROUTE}outstock/`;
export const WAREHOUSE_MANAGEMENT_DETAIL_ROUTE = `${WAREHOUSE_MANAGEMENT_ROUTE}:warehouseId/`;
export const WAREHOUSE_MANAGEMENT_EDIT_ROUTE = `${WAREHOUSE_MANAGEMENT_DETAIL_ROUTE}edit/`;

export const getWarehouseEditRoute = (warehouseId: string): string => {
  return WAREHOUSE_MANAGEMENT_EDIT_ROUTE.replace(":warehouseId", warehouseId);
};
export const getWarehouseInstockRoute = (warehouseId: string): string => {
  return WAREHOUSE_MANAGEMENT_INSTOCK_ROUTE.replace(
    ":warehouseId",
    warehouseId
  );
};
export const getWarehouseOutstockRoute = (warehouseId: string): string => {
  return WAREHOUSE_MANAGEMENT_OUTSTOCK_ROUTE.replace(
    ":warehouseId",
    warehouseId
  );
};
export const getWarehouseInventoryRoute = (warehouseId: string): string => {
  return WAREHOUSE_MANAGEMENT_INVENTORY_ROUTE.replace(
    ":warehouseId",
    warehouseId
  );
};

export const routes = {
  allowAll: true,
  name: "warehouse-management/",
  path: "/warehouse-management/",
  component: RenderRoutes,
  routes: [
    {
      exact: true,
      allowAll: true,
      name: "warehouse-list",
      path: WAREHOUSE_MANAGEMENT_ROUTE,
      component: WarehouseListComponent,
    },
    {
      exact: true,
      name: "warehouse-add",
      path: WAREHOUSE_MANAGEMENT_ADD_ROUTE,
      component: WarehouseSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      name: "warehouse-edit",
      path: WAREHOUSE_MANAGEMENT_EDIT_ROUTE,
      component: WarehouseSaveComponent,
      access_roles: ["super_admin", "master_admin", "branch_admin"],
    },
    {
      exact: true,
      allowAll: true,
      name: "warehouse-instock",
      path: WAREHOUSE_MANAGEMENT_INSTOCK_ROUTE,
      component: WarehouseStockSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "warehouse-outstock",
      path: WAREHOUSE_MANAGEMENT_OUTSTOCK_ROUTE,
      component: WarehouseStockSaveComponent,
    },
    {
      exact: true,
      allowAll: true,
      name: "warehouse-inventory",
      path: WAREHOUSE_MANAGEMENT_INVENTORY_ROUTE,
      component: WarehouseInventoryComponent,
    },
  ],
};


export const menuRoutes = [
  {
    allowAll: true,
    branchSelection: true,
    key: "warehouses",
    label: "Warehouses",
    route: WAREHOUSE_MANAGEMENT_ROUTE,
  },
  {
    allowAll: true,
    branchSelection: true,
    key: "warehouse-inventory",
    label: "Inventory",
    route: WAREHOUSE_MANAGEMENT_INVENTORY_ROUTE,
  },
  {
    allowAll: true,
    branchSelection: true,
    key: "warehouse-instock",
    label: "Instock",
    route: WAREHOUSE_MANAGEMENT_INSTOCK_ROUTE,
  },
  {
    allowAll: true,
    branchSelection: true,
    key: "warehouse-outstock",
    label: "Outstock",
    route: WAREHOUSE_MANAGEMENT_OUTSTOCK_ROUTE,
  },
];
import { BranchReducerActionProps, BranchType } from "interfaces";

const SET_BRANCH = "SET_BRANCH";

export const setBranch = (branch: BranchType, branches: Array<BranchType>) => {
  return {
    branch: branch,
    branches: branches,
    type: SET_BRANCH,
  };
};

export const branchReducer = (state = {}, action: BranchReducerActionProps) => {
  switch (action.type) {
    case SET_BRANCH:
      return {
        branch: action.branch,
        branches: action.branches,
      };
    default:
      return {
        branch: {},
        branches: [],
      };
  }
};

export default branchReducer;

import React from "react";
import ReactDOM from "react-dom/client";

import { ConfigProvider } from "antd";
import { BranchProvider } from "context/branch";
import { branchReducer } from "reducers/branch";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";

import "index.css";

const queryClient = new QueryClient();
const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#172b4d",
        colorTextBase: "#202020",
        // draw
        // colorBgBase: "#172b4d",
        // colorPrimaryBorder: "#172B4D",
        // colorInfo: "#172b4d",
      },
      components: {
        Layout: {
          colorBgHeader: "#172b4d",
        },
        Menu: {
          colorPrimaryBg: "#172b4d",
          // colorBgBase: "#172b4d",
        },
        Form: {
          fontSize: 16,
          colorTextHeading: "#202020",
        },
      },
    }}
  >
    <QueryClientProvider client={queryClient}>
      <BranchProvider reducer={branchReducer}>
        <App />
      </BranchProvider>
    </QueryClientProvider>
  </ConfigProvider>
);

import { useQuery } from "react-query";
import { getAdminUserDetail } from "actions";

export const useUser = (enabled = true) => {
  return useQuery("user", getAdminUserDetail, {
    retry: 0,
    enabled: enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
